import * as React from "react";
import {
    DownloadBlockData,
    DownloadFileItem,
    formatMultilineText,
    getFileSize,
} from "@plinknz/tah-website-elements";

type DownloadListProps = Pick<
    DownloadBlockData,
    "heading" | "description" | "files"
>;

export const DownloadList = ({
    heading,
    description,
    files,
}: DownloadListProps) => {
    const formatExt = (ext?: string) => ext?.replace(".", "").toUpperCase();
    const renderItem = ({ name, file }: DownloadFileItem) => (
        <a
            className="links-block-item"
            key={file.id}
            href={file.url}
            rel="noreferrer"
            target="_blank"
            download>
            <span className="links-block-item-title">{name || file.name}</span>
            <div className="links-block-item-meta">
                {file.size && (
                    <small className="links-block-item-size">
                        {getFileSize(file.size)} {formatExt(file.ext)}
                    </small>
                )}
                <i className="links-block-item-data fas fa-download fa-lg" />
            </div>
        </a>
    );

    return (
        <div
            className="links-block || constrain-width"
            data-testid="download-block">
            {heading && (
                <h3 className="links-block-title || heading-3">{heading}</h3>
            )}
            {description && (
                <div
                    className="links-block-description"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                        __html: formatMultilineText(description),
                    }}
                />
            )}
            <div className="links-block-list">
                {files.filter(({ file }) => file?.url).map(renderItem)}
            </div>
        </div>
    );
};
