import { ApiRequest } from "@plinknz/tah-website-elements";
import { registrationApi } from "../../service/register/api";
import { Hapu } from "./hapu";
import { getHapu } from "./hapu";
import { REGISTRATION_ENDPOINTS } from "./index";

enum MaraeList {
    "Raketapauma" = "Raketapauma",
    "Kuratahi" = "Kuratahi",
    "Te Ao Hou" = "Te Ao Hou",
    "Tirorangi" = "Tirorangi",
    "Tirohia" = "Tirohia",
    "Mokai" = "Mokai",
    "Maungarongo" = "Maungarongo",
    "Mangamingi" = "Mangamingi",
    "Tuhi Ariki" = "Tuhi Ariki",
    "Raetihi" = "Raetihi",
    "Marangai" = "Marangai",
    "Motekatoa" = "Motekatoa",
    "Waitahuparae" = "Waitahuparae",
    "Makaranui" = "Makaranui",
}

interface MaraeResponse {
    marae: {
        value: string;
    }[];
}

export interface Marae {
    description: string;
    id: number;
    hapu: Hapu[];
    disabled?: boolean;
}

export type MaraeSet = {
    [K in keyof typeof MaraeList]: Marae;
};

export const getMarae = async (
    api: ApiRequest = registrationApi
): Promise<MaraeSet> => {
    try {
        const { data: groups } = await api.get<MaraeResponse>(
            REGISTRATION_ENDPOINTS.groups
        );
        const hapu = await getHapu(api);

        const getMaraeFromList = (name: string) => {
            const m = groups?.marae.find(({ value }: any) => value === name);

            if (!m) {
                console.error(name, groups?.marae);
                throw new Error(`Could not find get marae ${name}`);
            }

            return m.value;
        };

        return {
            Raketapauma: {
                description: getMaraeFromList("Raketapauma"),
                id: 633020,
                hapu: [hapu.Rangituhia],
            },
            Kuratahi: {
                description: getMaraeFromList("Kuratahi"),
                id: 633022,
                hapu: [hapu.Rangituhia, hapu.Parenga],
            },
            "Te Ao Hou": {
                description: getMaraeFromList("Te Ao Hou"),
                id: 633024,
                hapu: [hapu.Tupoho, hapu["Rangi-ki-tai"]],
            },
            Tirorangi: {
                description: getMaraeFromList("Tirorangi"),
                id: 633026,
                hapu: [hapu.Tongaiti, hapu.Rangiteauria],
            },
            Tirohia: {
                description: getMaraeFromList("Tirohia"),
                id: 633025,
                hapu: [hapu.Hioi],
            },
            Mokai: {
                description: getMaraeFromList("Ngā Mōkai"),
                id: 633030,
                hapu: [hapu.Tongaiti],
            },
            Maungarongo: {
                description: getMaraeFromList("Maungārongo"),
                id: 633032,
                hapu: [hapu["Tui-o-Nuku"]],
            },
            Mangamingi: {
                description: getMaraeFromList("Mangamingi"),
                id: 633027,
                hapu: [hapu.Tamakana, hapu.Uenuku],
            },
            "Tuhi Ariki": {
                description: getMaraeFromList("Tuhi Ariki"),
                id: 633028,
                hapu: [hapu["Tuhi Ariki"]],
            },
            Raetihi: {
                description: getMaraeFromList("Raetihi (Te Puke)"),
                id: 633031,
                hapu: [hapu.Uenukumanawawiri, hapu.Uenuku],
            },
            Marangai: {
                description: getMaraeFromList("Marangai"),
                id: 633023,
                hapu: [hapu.Uenukumanawawiri],
            },
            Motekatoa: {
                description: getMaraeFromList("Mote Katoa"),
                id: 633033,
                hapu: [hapu.Uenuku, hapu.Patutokotoko],
            },
            Waitahuparae: {
                description: getMaraeFromList("Waitahuparae"),
                id: 633029,
                hapu: [hapu.Uenukumanawawiri, hapu.Patutokotoko],
            },
            Makaranui: {
                description: getMaraeFromList("Makaranui"),
                id: 633038,
                hapu: [hapu.Tamakana, hapu.Uenuku],
            },
        };
    } catch (e: unknown) {
        console.warn(e);
        throw e;
    }
};
