import { RouteProps } from "react-router-dom";
import { Route, Redirect } from "react-router-dom";
import * as React from "react";
import { canUseDom } from "@plinknz/tah-website-elements";
import * as ROUTES from "../config/router";

interface PrivateRouteProps extends RouteProps {
    redirect?: string;
    isLoggedIn: boolean;
    loading?: boolean;
}

export const PrivateRoute = ({
    redirect = ROUTES.membersLogin(),
    isLoggedIn,
    children,
    ...props
}: PrivateRouteProps) => {
    if (canUseDom() && isLoggedIn) {
        return <Route {...props}>{children}</Route>;
    }

    return <Redirect to={redirect} />;
};
