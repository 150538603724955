import * as React from "react";
import { CallToAction } from "./call-to-action";
import { DownloadBlockData } from "@plinknz/tah-website-elements";
import { DownloadList } from "./download-list";
import { DownloadPanels } from "./download-panels";

interface DownloadBlockProps {
    data: DownloadBlockData;
}

export const DownloadBlock = ({ data }: DownloadBlockProps) => {
    const { heading, description, files } = data;

    const haveImage = files.some((file) => file.image);

    if (haveImage) {
        if (files.length === 1) {
            const file = files[0];
            return (
                <CallToAction
                    file={file}
                    title={file.name || heading}
                    background={file.image}
                    description={description}
                />
            );
        }
        return <DownloadPanels files={files} title={heading} />;
    }
    return (
        <DownloadList
            files={files}
            heading={heading}
            description={description}
        />
    );
};
